
//==========  Import modules  ==========//

@import '../modules/global/config/_index';
@import '../modules/global/utils/_index';
@import '../modules/global/layouts/_index';

// mobile banner
.hero {
	background: $color-lightgrey-light;

	.hero-inner {
		height: 400px;
		background: url('/images/banners/banner-03.jpg') no-repeat;
		background-size: cover;

		@media #{$mobileL} {
			height: auto;
			background: none;
		}
	}
}

.main {
	max-width: 1300px;
}

// mobile youtube video
.mobile-video-wrapper {
	margin: $block-padding 0;
}

.mobile-video {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
	}

	@media #{$tablet} {
		display: none;
	}
}

// services callouts

.home-cta {
	position: relative;
	margin-bottom: $block-padding !important;
	text-align: center;
	line-height: 1.7;

	@media #{$tabletView} {
		@include fraction(2, $block-padding);
	}

	@media #{$laptopView} {
		@include fraction(4, $block-padding);
	}

	@media #{$laptop} {
		@include fraction(4, $block-padding);
	}

	h2 {
		margin-bottom: $block-padding*0.5;
		padding: $block-padding*0.5;
		font-size: $font-size-body;
		background: $color-primary;

		a {
			color: $color-white;
		}
	}

	img {
		margin-bottom: $block-padding*0.6;
	}

	div {

		@media #{$mobileL} {
			min-height: 140px;
		}

		@media #{$mobileXL} {
			min-height: 130px;
		}

		@media #{$tablet} {
			min-height: 170px;
		}

		@media #{$tabletXL} {
			min-height: 200px;
		}

	}

	.find-out-more {
		display: block;
		padding: $block-padding*0.2;
		margin-top: $block-padding;
		background: $color-secondary;
		color: $color-white;

		@media #{$tablet} {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			margin-top: 0;
		}
	}
	
	p {
		margin: 0!important;
	}
}

// News section
.content-bottom-container {
	padding: ($site-padding * 0.5);
	background: $color-secondary;
	color: $color-white;
	font-family: $font-family-title;
	text-align: center;

	@media #{$mobileL} {
		padding: ($site-padding * 1.6);
	}

	a {
		color: $color-white;
	}

	.content-bottom-wrapper {
		display: inline-block;
		max-width: 1000px;
	    padding: $block-padding;
	    border: 1px solid $color-sub;
	    text-align: left;
	}

	.news-items {
		margin-bottom: $block-padding;

		@media #{$tablet} {
			width: 40%;
			float: left;
			margin-bottom: 0;
		}

		h2 {
			margin-bottom: $block-padding;
			color: $color-white;
			font-size: $h5;

			@media #{$tabletXL} {
				font-size: $h4;
			}

		}

		.news {
			padding: $block-padding*0.4;
			margin: 0 0 2px;
			border: none;
			background: $color-sub;
			font-family: $font-family-body;
			line-height: 1.7;
			text-align: center;

			@media #{$tablet} {
				text-align: left;
			}

			.list-header {
				margin: 0;
			}

			.datetime {

				&:before {
					content: none;
				}
			}

			h2 {
				margin-bottom: 0;
				font-size: $font-size-body - 0.05;
				font-family: $font-family-body;

				&:hover {
					color: $color-midgrey;
				}
			}

			.meta {
				display: none;
			}

			.content {
				padding: 0;
				font-size: $font-size-body - 0.1;

				@media #{$tablet} {
					height: 45px;
					overflow: hidden;
				}
			}
		}
	}

	.flags-section {
		position: relative;
		margin-bottom: 0;
		font-size: $font-size-body - 0.15;
		line-height: 1.7;

		@media #{$tablet} {
			width: 57%;
			float: right;
		}

		@media #{$tabletXL} {
			font-size: $font-size-body - 0.1;
		}

		.block-header {

			@media #{$mobileL} {
				width: 65%;
			}
		}

		.title {
			color: $color-white;
			font-size: $h5;

			@media #{$tabletXL} {
				font-size: $h4;
			}
		}

		.more-btn {
			display: none;
			position: absolute;
			right: 0;
			top: 0;
			padding: $block-padding*0.2 $block-padding*0.5;
			background: $color-sub;

			@media #{$mobileL} {
				display: inline-block;
			}

			span {
				color: $color-primary;
			}
		}

		#flags {

			li {

				.items-row {
					width: 100%;
					text-align: center;

					li {

						@media #{$maxMobileL} {
							@include fraction(2, $block-padding);
						}

						@media #{$mobileL} {
							@include fraction(4, $block-padding);
						}
					}

					img {
						width: 100%;
					}
				}
			}
		}

		.flags-slider-container {
			position: relative;
		}

		.bx-viewport {
			width: 75% !important;
			margin: $block-padding $block-padding*1.3;

			@media #{$mobileL} {
				width: 84% !important;
			}

			@media #{$mobileXL} {
				width: 87% !important;
			}

			@media #{$tablet} {
				width: 84% !important;
				max-height: 188px !important;
			}

			@media #{$tabletXL} {
				width: 88% !important;
			}
		}

		.bx-controls {

			a {
				@include centreY();
				color: rgba($color-black,0);
				opacity: .5;
			}

			.bx-prev {
				left: 0;

				&:before {
					content: '‹';
					color: $color-white;
					font-size: 4rem;
				}
			}

			.bx-next {
				right: 0;

				&:after {
					content: '›';
					color: $color-white;
					font-size: 4rem;
				}
			}
		}
	}
}

// pre footer
.pre-footer {
	padding: 0;
	background: $color-lightgrey;

	.pre-footer-inner {
		margin: $site-padding;
	    padding: $block-padding;
	    background: $color-white;
	    border: 1px solid $color-midgrey;

	    @media #{$tablet} {
	    	margin: ($site-padding * 1.6) ($site-padding * 3);
	    }

	    @media #{$laptop} {
	    	margin: ($site-padding * 1.6) auto;
	    	max-width: 1000px;
	    }

	    .block {
	    	margin-bottom: 0;
	    }

	    .pre-footer-content {

	    	@media #{$tablet} {
		    	width: 65%;
	    		float: left;
	    	}

	    	.title {
	    		margin-bottom: $block-padding*0.5;
	    		font-size: $h5;
	    	}

	    	p {
	    		margin: 0 0 $paragraph-margin*0.5;
	    		line-height: 1.7;
	    	}
	    }

			.home-accreditations {
				display: inline-block;
				//@include centre(y);

				.sra {
					@include fraction(1/2);
				}
				.aipp {
					@include fraction(1/2);
				}
			}
	}
}
