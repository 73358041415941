@charset "UTF-8";
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

@keyframes hippyshake {
  0% {
    transform: translateX(2px); }
  20% {
    transform: translateX(0); }
  40% {
    transform: translateX(-2px); }
  60% {
    transform: translateX(0); }
  80% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

@keyframes loading {
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

.hidden {
  display: none; }

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .loading p {
    color: #fff; }
  .loading .dot {
    width: 14px;
    height: 14px;
    margin: 0 7px;
    background: #fff; }
  .loading p {
    margin: 0;
    text-align: center; }
  .loading .dot {
    display: inline-block;
    transform: scale(0);
    border-radius: 50%;
    animation: loading 1s ease infinite; }
    .loading .dot:nth-child(2) {
      animation: loading 1s ease infinite 0.3s; }
    .loading .dot:nth-child(3) {
      animation: loading 1s ease infinite 0.6s; }

.hero {
  background: #fbfbfb; }
  .hero .hero-inner {
    height: 400px;
    background: url("/images/banners/banner-03.jpg") no-repeat;
    background-size: cover; }
    @media only screen and (min-width: 32.5em) {
      .hero .hero-inner {
        height: auto;
        background: none; } }

.main {
  max-width: 1300px; }

.mobile-video-wrapper {
  margin: 1.5rem 0; }

.mobile-video {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0; }
  .mobile-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; }
  @media only screen and (min-width: 50em) {
    .mobile-video {
      display: none; } }

.home-cta {
  position: relative;
  margin-bottom: 1.5rem !important;
  text-align: center;
  line-height: 1.7; }
  @media (min-width: 520px) and (max-width: 959px) {
    .home-cta {
      float: left;
      width: calc((100% - 1.5rem) / 2);
      margin: 0 0 0 1.5rem; }
      .home-cta:nth-child(2n+1) {
        margin-left: 0; } }
  @media (min-width: 960px) and (max-width: 1119px) {
    .home-cta {
      float: left;
      width: calc((100% - 4.5rem) / 4);
      margin: 0 0 0 1.5rem; }
      .home-cta:nth-child(4n+1) {
        margin-left: 0; } }
  @media only screen and (min-width: 70em) {
    .home-cta {
      float: left;
      width: calc((100% - 4.5rem) / 4);
      margin: 0 0 0 1.5rem; }
      .home-cta:nth-child(4n+1) {
        margin-left: 0; } }
  .home-cta h2 {
    margin-bottom: 0.75rem;
    padding: 0.75rem;
    font-size: 0.85rem;
    background: #ac9e03; }
    .home-cta h2 a {
      color: #fff; }
  .home-cta img {
    margin-bottom: 0.9rem; }
  @media only screen and (min-width: 32.5em) {
    .home-cta div {
      min-height: 140px; } }
  @media only screen and (min-width: 40em) {
    .home-cta div {
      min-height: 130px; } }
  @media only screen and (min-width: 50em) {
    .home-cta div {
      min-height: 170px; } }
  @media only screen and (min-width: 60em) {
    .home-cta div {
      min-height: 200px; } }
  .home-cta .find-out-more {
    display: block;
    padding: 0.3rem;
    margin-top: 1.5rem;
    background: #192026;
    color: #fff; }
    @media only screen and (min-width: 50em) {
      .home-cta .find-out-more {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-top: 0; } }
  .home-cta p {
    margin: 0 !important; }

.content-bottom-container {
  padding: 0.75rem;
  background: #192026;
  color: #fff;
  font-family: "Merriweather", serif;
  text-align: center; }
  @media only screen and (min-width: 32.5em) {
    .content-bottom-container {
      padding: 2.4rem; } }
  .content-bottom-container a {
    color: #fff; }
  .content-bottom-container .content-bottom-wrapper {
    display: inline-block;
    max-width: 1000px;
    padding: 1.5rem;
    border: 1px solid #2c363e;
    text-align: left; }
  .content-bottom-container .news-items {
    margin-bottom: 1.5rem; }
    @media only screen and (min-width: 50em) {
      .content-bottom-container .news-items {
        width: 40%;
        float: left;
        margin-bottom: 0; } }
    .content-bottom-container .news-items h2 {
      margin-bottom: 1.5rem;
      color: #fff;
      font-size: 1rem; }
      @media only screen and (min-width: 60em) {
        .content-bottom-container .news-items h2 {
          font-size: 1.1rem; } }
    .content-bottom-container .news-items .news {
      padding: 0.6rem;
      margin: 0 0 2px;
      border: none;
      background: #2c363e;
      font-family: "Raleway", sans-serif;
      line-height: 1.7;
      text-align: center; }
      @media only screen and (min-width: 50em) {
        .content-bottom-container .news-items .news {
          text-align: left; } }
      .content-bottom-container .news-items .news .list-header {
        margin: 0; }
      .content-bottom-container .news-items .news .datetime:before {
        content: none; }
      .content-bottom-container .news-items .news h2 {
        margin-bottom: 0;
        font-size: 0.8rem;
        font-family: "Raleway", sans-serif; }
        .content-bottom-container .news-items .news h2:hover {
          color: #bbb; }
      .content-bottom-container .news-items .news .meta {
        display: none; }
      .content-bottom-container .news-items .news .content {
        padding: 0;
        font-size: 0.75rem; }
        @media only screen and (min-width: 50em) {
          .content-bottom-container .news-items .news .content {
            height: 45px;
            overflow: hidden; } }
  .content-bottom-container .flags-section {
    position: relative;
    margin-bottom: 0;
    font-size: 0.7rem;
    line-height: 1.7; }
    @media only screen and (min-width: 50em) {
      .content-bottom-container .flags-section {
        width: 57%;
        float: right; } }
    @media only screen and (min-width: 60em) {
      .content-bottom-container .flags-section {
        font-size: 0.75rem; } }
    @media only screen and (min-width: 32.5em) {
      .content-bottom-container .flags-section .block-header {
        width: 65%; } }
    .content-bottom-container .flags-section .title {
      color: #fff;
      font-size: 1rem; }
      @media only screen and (min-width: 60em) {
        .content-bottom-container .flags-section .title {
          font-size: 1.1rem; } }
    .content-bottom-container .flags-section .more-btn {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      padding: 0.3rem 0.75rem;
      background: #2c363e; }
      @media only screen and (min-width: 32.5em) {
        .content-bottom-container .flags-section .more-btn {
          display: inline-block; } }
      .content-bottom-container .flags-section .more-btn span {
        color: #ac9e03; }
    .content-bottom-container .flags-section #flags li .items-row {
      width: 100%;
      text-align: center; }
      @media only screen and (max-width: 519px) {
        .content-bottom-container .flags-section #flags li .items-row li {
          float: left;
          width: calc((100% - 1.5rem) / 2);
          margin: 0 0 0 1.5rem; }
          .content-bottom-container .flags-section #flags li .items-row li:nth-child(2n+1) {
            margin-left: 0; } }
      @media only screen and (min-width: 32.5em) {
        .content-bottom-container .flags-section #flags li .items-row li {
          float: left;
          width: calc((100% - 4.5rem) / 4);
          margin: 0 0 0 1.5rem; }
          .content-bottom-container .flags-section #flags li .items-row li:nth-child(4n+1) {
            margin-left: 0; } }
      .content-bottom-container .flags-section #flags li .items-row img {
        width: 100%; }
    .content-bottom-container .flags-section .flags-slider-container {
      position: relative; }
    .content-bottom-container .flags-section .bx-viewport {
      width: 75% !important;
      margin: 1.5rem 1.95rem; }
      @media only screen and (min-width: 32.5em) {
        .content-bottom-container .flags-section .bx-viewport {
          width: 84% !important; } }
      @media only screen and (min-width: 40em) {
        .content-bottom-container .flags-section .bx-viewport {
          width: 87% !important; } }
      @media only screen and (min-width: 50em) {
        .content-bottom-container .flags-section .bx-viewport {
          width: 84% !important;
          max-height: 188px !important; } }
      @media only screen and (min-width: 60em) {
        .content-bottom-container .flags-section .bx-viewport {
          width: 88% !important; } }
    .content-bottom-container .flags-section .bx-controls a {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: transparent;
      opacity: .5; }
    .content-bottom-container .flags-section .bx-controls .bx-prev {
      left: 0; }
      .content-bottom-container .flags-section .bx-controls .bx-prev:before {
        content: '‹';
        color: #fff;
        font-size: 4rem; }
    .content-bottom-container .flags-section .bx-controls .bx-next {
      right: 0; }
      .content-bottom-container .flags-section .bx-controls .bx-next:after {
        content: '›';
        color: #fff;
        font-size: 4rem; }

.pre-footer {
  padding: 0;
  background: #eee; }
  .pre-footer .pre-footer-inner {
    margin: 1.5rem;
    padding: 1.5rem;
    background: #fff;
    border: 1px solid #bbb; }
    @media only screen and (min-width: 50em) {
      .pre-footer .pre-footer-inner {
        margin: 2.4rem 4.5rem; } }
    @media only screen and (min-width: 70em) {
      .pre-footer .pre-footer-inner {
        margin: 2.4rem auto;
        max-width: 1000px; } }
    .pre-footer .pre-footer-inner .block {
      margin-bottom: 0; }
    @media only screen and (min-width: 50em) {
      .pre-footer .pre-footer-inner .pre-footer-content {
        width: 65%;
        float: left; } }
    .pre-footer .pre-footer-inner .pre-footer-content .title {
      margin-bottom: 0.75rem;
      font-size: 1rem; }
    .pre-footer .pre-footer-inner .pre-footer-content p {
      margin: 0 0 1rem;
      line-height: 1.7; }
    .pre-footer .pre-footer-inner .home-accreditations {
      display: inline-block; }
      .pre-footer .pre-footer-inner .home-accreditations .sra {
        float: left;
        width: 50%; }
      .pre-footer .pre-footer-inner .home-accreditations .aipp {
        float: left;
        width: 50%; }
